import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule, Http } from '@angular/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
// import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IonicTimepickerModule } from '@logisticinfotech/ionic-timepicker';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
// import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AddLocationPage } from '../app/add-location/add-location.page';
import { UpdateLocationPage } from '../app/update-location/update-location.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AutoCompleteModule } from 'ionic4-auto-complete';
import  { NotificationSettingsPage} from '../app/notification-settings/notification-settings.page';
import { SelectVehicleIconPage } from '../app/select-vehicle-icon/select-vehicle-icon.page';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Downloader } from '@ionic-native/downloader/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/Camera/ngx';
// import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { File, FileEntry } from '@ionic-native/File/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { RequestClipComponent } from './component/request-clip/request-clip.component';

// adding icons
library.add(fas);

@NgModule({
  declarations: [AppComponent,
    RequestClipComponent,
    AddLocationPage,
    UpdateLocationPage,
    NotificationSettingsPage,
    SelectVehicleIconPage

  ],
  entryComponents: [
    AddLocationPage,
    UpdateLocationPage,
    NotificationSettingsPage,
    SelectVehicleIconPage,
    RequestClipComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    IonicTimepickerModule,
    ReactiveFormsModule,
    FormsModule,
    // DatePicker,
    FontAwesomeModule
    // AutoCompleteModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NativeStorage,
    // NativeAudio,
    // Geolocation,
    // LocationAccuracy,
    // BackgroundGeolocation,
    // BackgroundMode,
    DatePicker,
    ScreenOrientation,
    OneSignal,
    Downloader,
    Camera,
    // ImagePicker,
    MediaCapture,
    File,
    FilePath,
    FileChooser
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
